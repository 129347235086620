import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import Page from "../../page"
import { mq, HideBeforeLarge, ShowBeforeLarge } from "../../helpers"
import Container from "../../container"
import PageTitle from "../../page-title"
import Text from "../../text"
import Button from "../../button"
import YoutubeVideo from "./YoutubeVideo"

const Heading = styled(Container)`
  ${mq({
    display: "flex",
    flexDirection: ["column", "column", "row"],
    marginBottom: ["50px", "50px", "85px"],
  })}
`

const HeadingBox = styled.div`
  ${mq({
    flex: 2,
    marginLeft: [0, null, "37px"],
    marginRight: [0, 0, "69px", "116px"],
  })}
`

const HeadingText = styled(Text)`
  ${mq({
    fontSize: ["15px", "15px", "15px", "18px"],
    margin: "20px 0 0",
  })}
`

const SubHeadingText = styled(Text)`
  font-size: 15px;
  margin-top: 45px;
  max-width: 450px;
`

const VideoHeadingWrapper = styled.div`
  ${mq({
    flex: 1,
    marginTop: ["45px", "35px", 0],
  })}
`

const VideoWrapper = styled.div`
  ${mq({
    flex: 1,
    marginTop: ["40px", 0],
    marginRight: [0, "42px", "37px", "104px"],
    "&:first-of-type": {
      marginTop: 0,
    },
    "&:last-child": {
      marginRight: 0,
    },
  })}
`

const VideoTitle = styled.h3`
  ${props =>
    mq({
      fontFamily: "Bitter",
      fontSize: ["13px", "15px"],
      fontWeight: "normal",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#5f5f5f",
      margin: 0,
      marginTop: props.heading ? ["6px", "15px"] : ["10px", "10px", "15px"],
    })}
`

const MoreVideos = styled.div`
  ${mq({
    backgroundColor: "#ffffff",
    padding: ["50px 0", "50px 0", "85px 37px"],
  })}
`

const videos = [
  {
    title: "Apresentação - O que é Labor?",
    videoId: "KBSbVcqJgLg",
  },
  {
    title: "Finanças - Conceitos básicos",
    videoId: "KXu18gqrDqI",
  },
  {
    title: "Metodologia - Como organizar seu negócio",
    videoId: "nR349B3dtfM",
  },
  {
    title: "Métricas - Qual a importância de medir?",
    videoId: "R_ddRlCudy4",
  },
]

const VideosWrapper = styled.div`
  ${mq({
    display: "flex",
    flexDirection: ["column", "row"],
    width: "100%",
    marginBottom: [0, "50px", "50px", "71px"],
    "&:last-child": {
      marginBottom: 0,
    },
  })}
`

function mountVideoPairs() {
  const videoComponents = videos.map(({ videoId, title }) => (
    <VideoWrapper key={videoId}>
      <YoutubeVideo videoId={videoId} />
      <VideoTitle>{title}</VideoTitle>
    </VideoWrapper>
  ))

  let videoPairs = []
  for (let i = 0; i < videoComponents.length; i += 2) {
    videoPairs = videoPairs.concat(
      <VideosWrapper key={i}>
        {[videoComponents[i], videoComponents[i + 1]]}
      </VideosWrapper>
    )
  }

  return videoPairs
}

export default function HowToUsePage() {
  const [deviceId, setDeviceId] = useState(null)

  useEffect(() => {
    setDeviceId(
      typeof window !== 'undefined' && window.amplitude && typeof window.amplitude.getInstance().options !== 'undefined' ? 
      '?amp_device_id='.concat(window.amplitude.getInstance().options.deviceId) : 
      '?'
    )
  }, [])
  const videoHeading = (
    <VideoHeadingWrapper>
      <YoutubeVideo videoId="voZT0l9u3_Y" />
      <VideoTitle heading>Tutorial - Como usar o Labor</VideoTitle>
    </VideoHeadingWrapper>
  )

  return (
    <Page>
      <Heading>
        <HeadingBox>
          <PageTitle>Como Usar</PageTitle>

          <HeadingText>
            Assista vídeos <strong>tutoriais</strong> do Labor nesta galeria,
            assim como <strong>outros conteúdos</strong> relacionados a
            produtividade e saúde financeira.
          </HeadingText>

          <ShowBeforeLarge>{videoHeading}</ShowBeforeLarge>

          <SubHeadingText>
            Vamos colocar estes ensinamentos em prática? Controle sua
            produtividade e lucratividade.
          </SubHeadingText>
          {/* <Button
            as="a"
            href={`${process.env.WEB_APP_URL}/cadastrar${deviceId}`}
            style={{ marginTop: "25px" }}
          >
            Comece Gratuitamente
          </Button> */}
        </HeadingBox>

        <HideBeforeLarge style={{ flex: 3 }}>{videoHeading}</HideBeforeLarge>
      </Heading>
      <MoreVideos>
        <Container>{mountVideoPairs()}</Container>
      </MoreVideos>
    </Page>
  )
}
