import React from "react"
import HowToPage from "../components/pages/how-to"
import SEO from "../components/seo"

const ComoUsarPage = () => (
  <>
    <SEO title="Como Usar" />
    <HowToPage />
  </>
)

export default ComoUsarPage
