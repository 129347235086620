import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default function YoutubeVideo({ videoId }) {
  return (
    <Wrapper>
      <Iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allowFullscreen
      />
    </Wrapper>
  );
}

YoutubeVideo.propTypes = {
  videoId: PropTypes.string.isRequired,
};
